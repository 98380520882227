/* eslint-disable jsx-a11y/anchor-is-valid */

'use client';

import { clsx } from 'clsx';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '@/utils/firebase';
import { getEmailFromRGIS } from '@/utils/cookies';
import { Button } from '@/components/raven/ui/Button';
import type { VerifyIdTokenResult } from '@/utils/sso';
import { useSiteContext } from '@/context/SiteContext';
import { getCleanRGIS } from '@/utils/newsletter/auth-utils';
import { TextField } from '@/components/raven/Inputs/TextField';
import { useGaContext } from '@/context/GoogleAnalyticsContext';
import TubeSpinner from '@themaven-net/raven-assets/TubeSpinner';
import type {
	NewsletterApi,
	NewsletterPlacement,
	NewsleterSubscription,
} from '@/types/newsletter';
import {
	useId,
	type FC,
	useMemo,
	useState,
	useEffect,
	useCallback,
	type ChangeEvent,
} from 'react';
import {
	AppleLogo,
	GoogleColored,
	FacebookColored,
	MicrosoftColored,
} from '@themaven-net/raven-assets';
import {
	LoginType,
	OAuthPhase,
	LoginProvider,
	EmailLinkPhase,
	useLoginContext,
	OAuthProviderType,
} from '@/context/LoginContext';

import styles from './styles.module.css';
import { Checkbox } from '../Inputs/Checkbox';

export interface NewsletterProps {
	isInContent?: boolean;
	newsletterIds?: string[]; // newsletter product item IDs
	onAllSubscribed?: () => void;
	placement: NewsletterPlacement;
}

export const fetchNewsletters = async (
	productItemIds: string[],
): Promise<{
	newsletters: NewsletterApi[];
}> => {
	if (productItemIds.length === 0) {
		return {
			newsletters: [],
		};
	}

	const res = await fetch(
		`/.api/newsletters?productItemIds=${productItemIds.join(',')}`,
		{
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
		},
	);
	if (!res.ok) {
		throw new Error(
			`[${res.status}] Invalid /newsletters response ${res.statusText ?? ''}`,
		);
	}

	return res.json();
};

export const fetchNewsletterSubscriptions = async (
	rgisCookie: string,
	productItemIds: string[],
): Promise<{
	subscriptions: NewsleterSubscription[];
}> => {
	if (productItemIds.length === 0) {
		return {
			subscriptions: [],
		};
	}

	const res = await fetch(`/.api/newsletters/subscriptions`, {
		body: JSON.stringify({
			productItemIds,
		}),
		headers: {
			'Content-Type': 'application/json',
			'X-RGIS': getCleanRGIS(rgisCookie),
		},
		method: 'POST',
	});
	if (!res.ok) {
		throw new Error(
			`[${res.status}] Invalid /newsletters/subscriptions response ${res.statusText ?? ''}`,
		);
	}

	return res.json();
};

export const fetchNewsletterSubscribe = async (
	rgisCookie: string,
	productItemIds: string[],
): Promise<boolean> => {
	const res = await fetch(`/.api/newsletters/subscribe`, {
		body: JSON.stringify({
			newsletters: productItemIds.map((productItemId) => ({
				productItemId,
				subscribe: true,
			})),
		}),
		headers: {
			'Content-Type': 'application/json',
			'X-RGIS': getCleanRGIS(rgisCookie),
		},
		method: 'POST',
	});
	if (!res.ok) {
		throw new Error(
			`[${res.status}] Invalid /newsletters/subscribe response ${res.statusText ?? ''}`,
		);
	}
	await res.json();
	return true;
};

const NewsletterComponent: FC<NewsletterProps> = (props) => {
	const { isInContent, newsletterIds, onAllSubscribed, placement } = props;
	const { config: siteConfig, toaster } = useSiteContext();
	const {
		afterCompleteLoginFnRef,
		emailLinkPhase,
		handleAfterFirebaseLogin,
		handleSendEmailLink,
		isLoading,
		logOut,
		oauthPhase,
		rgisCookie,
		signInOAuth,
	} = useLoginContext();
	const { sendGaEvent } = useGaContext();
	const [newsletters, setNewsletters] = useState<NewsletterApi[]>([]);
	const [newsletterSubs, setNewsletterSubs] = useState<NewsleterSubscription[]>(
		[],
	);
	const [selectedIds, setSelectIds] = useState<string[]>([]);
	const [email, setEmail] = useState('');
	const [isEmailValid, setEmailValid] = useState(false);
	const [isFetching, setIsFetching] = useState<boolean>(true);
	const checkboxId = useId();
	const { newsletters: newslettersConfig } = siteConfig;
	const { setToast } = toaster;

	// calculate newsletters not subscribed
	const newslettersToSubscribe = useMemo(() => {
		return newsletters.filter(
			(newsletter) =>
				!newsletterSubs.some(
					(newsletterSub) =>
						newsletterSub.productItemId === newsletter.productItemId,
				),
		);
	}, [newsletters, newsletterSubs]);
	const allSubscribed =
		newsletters.length > 0 && newslettersToSubscribe.length === 0;

	useEffect(() => {
		if (allSubscribed && onAllSubscribed) {
			onAllSubscribed();
		}
	}, [allSubscribed, onAllSubscribed]);

	const isMultipleNewsletter: boolean = newslettersConfig?.ids?.length > 1;
	const isLoggedIn = !!rgisCookie;
	const emailFromRGIS = getEmailFromRGIS(rgisCookie);

	// use prop newsletter IDs first, if doesn't exist, use ids from site config
	const newsletterIdList = newsletterIds ?? siteConfig.newsletters.ids;

	const newslettersSelected = useMemo(() => {
		if (isMultipleNewsletter) {
			return selectedIds;
		}
		// select as default if it is single newsletter
		return newsletterIdList;
	}, [isMultipleNewsletter, newsletterIdList, selectedIds]);

	const handleSubmitEmail =
		(resend = false) =>
		async () => {
			if (newslettersSelected.length === 0) {
				setToast({
					message: 'Please select at least one newsletter',
					type: 'error',
				});
				return;
			}
			// send product item IDs in query string to subscribe for email link
			const loginUrl = `${window.location.protocol}//${window.location.host}/newsletters?productItemIds=${newslettersSelected.join(',')}`;
			await handleSendEmailLink(email, loginUrl);
			if (resend) {
				setToast({
					message: 'The email has been sent',
					type: 'success',
				});
			}
		};

	const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value === '') {
			// need to handle empty string separately since it is valid in email form
			setEmailValid(false);
		} else {
			setEmailValid(e.target.validity.valid);
		}
		setEmail(e.target.value);
	};

	const handleOAuthSignIn = (provider: OAuthProviderType): (() => void) => {
		return () => {
			if (newslettersSelected.length === 0) {
				setToast({
					message: 'Please select at least one newsletter',
					type: 'error',
				});
				return;
			}

			signInOAuth(provider);
		};
	};

	const handleSelectNewsletter = (productItemId?: string) => {
		if (productItemId) {
			if (selectedIds.includes(productItemId)) {
				setSelectIds((prev) => prev.filter((item) => item !== productItemId));
			} else {
				setSelectIds((prev) => [...prev, productItemId]);
			}
		}
	};

	const handleLogout = () => {
		setNewsletterSubs([]);
		setSelectIds([]);
		logOut();

		setToast({
			message: 'Signed out successfully',
			type: 'success',
		});
	};

	const subscribeToNewsletters = useCallback(
		async (rgis: string, productItemIds: string[]) => {
			let success = false;
			if (productItemIds.length > 0) {
				success = await fetchNewsletterSubscribe(rgis, productItemIds);
			}
			if (success) {
				sendGaEvent('newsletter-signup-event', {
					placement,
					productItemIds,
					subscriptionType: 'singleSubscription',
					userType: 'new',
				});
			}
		},
		[placement, sendGaEvent],
	);

	const onSubscribe = () => {
		if (isMultipleNewsletter && newslettersSelected.length === 0) {
			setToast({
				message: 'Please select at least one newsletter',
				type: 'error',
			});
			return;
		}
		if (rgisCookie) {
			(async () => {
				let productItemIdsToSubscribe: string[] = [];
				if (isMultipleNewsletter) {
					productItemIdsToSubscribe = selectedIds;
				} else {
					// select as default if it is single newsletter
					productItemIdsToSubscribe = newslettersConfig.ids;
				}

				setIsFetching(true);

				try {
					await subscribeToNewsletters(rgisCookie, productItemIdsToSubscribe);
					const response = await fetchNewsletterSubscriptions(
						rgisCookie,
						newsletterIdList,
					);
					setNewsletterSubs(response.subscriptions);
				} catch (err) {
					// eslint-disable-next-line no-console
					console.log(err);
					window.reportErrorToPetametrics(
						'Subscribe to newsletter failed',
						err as Error,
					);
					setToast({ message: 'Something went wrong', type: 'error' });
				}

				setIsFetching(false);
				setSelectIds([]);

				setToast({
					message: "You're subscribed",
					type: 'success',
				});
			})();
		}
	};

	// Side effects
	useEffect(() => {
		const init = async () => {
			setIsFetching(true);
			try {
				const response = await fetchNewsletters(newsletterIdList);
				setNewsletters(response.newsletters);

				if (rgisCookie) {
					const res = await fetchNewsletterSubscriptions(
						rgisCookie,
						newsletterIdList,
					);
					setNewsletterSubs(res.subscriptions);
				}
				setIsFetching(false);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
				window.reportErrorToPetametrics(
					'Initial fetching newsletters failed',
					err as Error,
				);
				setToast({ message: 'Something went wrong', type: 'error' });
			}
		};

		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handleAfterFirebaseLogin();
	}, [handleAfterFirebaseLogin]);

	useEffect(() => {
		afterCompleteLoginFnRef.current = (
			tokenResult: VerifyIdTokenResult,
			loginType: LoginType,
		) => {
			let productItemIds: string[] = [];
			if (loginType === LoginType.EMAIL_LINK) {
				// get product item ids to subscribe in query string
				const urlParams = new URLSearchParams(window.location.search);
				const productItemIdsString = urlParams.get('productItemIds');
				if (productItemIdsString) {
					productItemIds = productItemIdsString.split(',');
				}
			} else {
				productItemIds = newslettersSelected;
			}
			const rgis = tokenResult.tokens.RGIS;
			if (rgis) {
				setIsFetching(true);
				subscribeToNewsletters(rgis, productItemIds).finally(async () => {
					try {
						const response = await fetchNewsletterSubscriptions(
							rgis,
							newsletterIdList,
						);
						setNewsletterSubs(response.subscriptions);
					} catch (err) {
						// eslint-disable-next-line no-console
						console.log(err);
						window.reportErrorToPetametrics(
							'Fetching newsletter subscriptions failed',
							err as Error,
						);
						setToast({ message: 'Something went wrong', type: 'error' });
					} finally {
						setIsFetching(false);
					}

					setToast({
						message: "You're now signed in and subscribed",
						type: 'success',
					});

					setSelectIds([]);

					// remove query string from url after subscription is done
					window.history.replaceState(
						{},
						document.title,
						window.location.pathname,
					);
				});
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subscribeToNewsletters, newslettersSelected]);

	// don't render when newsletter is subscribed and it is inline component
	if (allSubscribed && isInContent) {
		return null;
	}

	if (
		oauthPhase === OAuthPhase.SIGNING_IN ||
		emailLinkPhase === EmailLinkPhase.SENDING_EMAIL ||
		emailLinkPhase === EmailLinkPhase.SIGNING_IN ||
		isLoading ||
		isFetching
	) {
		return (
			<div className={styles.spinner}>
				<TubeSpinner />
			</div>
		);
	}

	// render email sent page
	if (emailLinkPhase === EmailLinkPhase.SENT) {
		return (
			<div className={styles.emailSentWrapper}>
				<div>
					<div className={styles.emailSentHeader}>
						You&apos;re almost there!
					</div>
					<div className={styles.emailSentSubhead}>
						Check your email <strong>{email}</strong> to complete the sign-in
						process. If it&apos;s missing, check spam or request a new one.
					</div>
					<div className={styles.resendEmail}>
						Did not get the email?&nbsp;
						<Button onClick={handleSubmitEmail(true)} variation="link">
							Resend
						</Button>
					</div>
				</div>
			</div>
		);
	}

	const title =
		placement === 'header'
			? newslettersConfig?.title
			: newslettersConfig?.[placement]?.title || newslettersConfig?.title;
	const subhead =
		placement === 'header'
			? newslettersConfig?.subhead
			: newslettersConfig?.[placement]?.subhead || newslettersConfig?.subhead;
	const disclaimer =
		placement === 'header'
			? newslettersConfig?.disclaimer
			: newslettersConfig?.[placement]?.disclaimer ||
				newslettersConfig?.disclaimer;
	const thankyouTitle =
		placement === 'header'
			? newslettersConfig?.thankyou?.title
			: newslettersConfig?.[placement]?.thankyou?.title ||
				newslettersConfig?.thankyou?.title;
	const thankyouSubhead =
		placement === 'header'
			? newslettersConfig?.thankyou?.subhead
			: newslettersConfig?.[placement]?.thankyou?.subhead ||
				newslettersConfig?.subhead;

	if (allSubscribed) {
		return (
			<div
				className={clsx(styles.thanksContainer, {
					'is-style-pairing-2': isInContent,
				})}
			>
				<div className={styles.thanksInnerContainer}>
					<div className={styles.title}>{thankyouTitle}</div>
					<div className={styles.subhead}>{thankyouSubhead}</div>
				</div>
			</div>
		);
	}

	return (
		<div
			className={clsx(styles.wrapper, 'container-inline', {
				[styles.isInContent]: isInContent,
			})}
		>
			<div
				className={clsx(styles.container, {
					'is-style-pairing-2': isInContent,
				})}
			>
				<div className={styles.content}>
					<div className={styles.title}>{title}</div>
					<div className={styles.subhead}>{subhead}</div>
					{isMultipleNewsletter && (
						<div className={styles.newslettersList}>
							{newslettersToSubscribe.map((newsletterData, i) => (
								<Checkbox
									checked={selectedIds.includes(
										newsletterData.productItemId as string,
									)}
									id={`newsletter-${newsletterData.productItemId}-${checkboxId}`}
									key={`newsletter-${newsletterData.productItemId}-${checkboxId}`}
									label={newsletterData.title}
									name={`newsletter-${newsletterData.productItemId}-${checkboxId}`}
									onChange={() =>
										handleSelectNewsletter(newsletterData.productItemId)
									}
								/>
							))}
						</div>
					)}
				</div>
				{!isLoggedIn && (
					<div
						className={clsx(styles.form, { 'container-inline': isInContent })}
					>
						<div className={styles.socialButtons}>
							<Button
								onClick={handleOAuthSignIn(OAuthProviderType.GOOGLE)}
								variation="secondary"
							>
								Google <GoogleColored width="16" />
							</Button>
							<Button
								onClick={handleOAuthSignIn(OAuthProviderType.FACEBOOK)}
								variation="secondary"
							>
								Facebook <FacebookColored width="16" />
							</Button>
							<Button
								onClick={handleOAuthSignIn(OAuthProviderType.MICROSOFT)}
								variation="secondary"
							>
								Microsoft <MicrosoftColored width="16" />
							</Button>
							<Button
								onClick={handleOAuthSignIn(OAuthProviderType.APPLE)}
								variation="secondary"
							>
								Apple <AppleLogo width="16" />
							</Button>
						</div>
						<p className={styles.logInSeparator}>
							<span>or sign in with</span>
						</p>
						<div className={styles.formContainer}>
							<TextField
								className={styles.emailInput}
								label=""
								labelHidden
								name="text"
								onChange={onEmailChange}
								placeholder="Email Address"
								type="email"
							/>
							<Button
								className={styles.submitButton}
								disabled={!isEmailValid}
								onClick={handleSubmitEmail()}
							>
								Sign up
							</Button>
						</div>
						<div className={styles.disclaimerWrapper}>
							{/* eslint-disable react/no-danger */}
							<div
								className={styles.disclaimer}
								dangerouslySetInnerHTML={{
									__html: disclaimer || '',
								}}
							/>
							{/* eslint-disable react/no-danger */}
						</div>
					</div>
				)}
				{isLoggedIn && (
					<div
						className={clsx(styles.form, { 'container-inline': isInContent })}
					>
						<Button className={styles.subscribeButton} onClick={onSubscribe}>
							Subscribe
						</Button>
						<div className={styles.loggedInText}>
							You&apos;re logged in as&nbsp;
							<strong>{emailFromRGIS}</strong>
							&nbsp;Not you?&nbsp;
							<Button onClick={handleLogout} variation="link">
								Use a different account.
							</Button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export const Newsletter: FC<NewsletterProps> = (props) => {
	const { firebase } = useSiteContext();

	const auth = useMemo(() => {
		return getAuth(firebaseApp(firebase.config));
	}, [firebase]);

	return (
		<LoginProvider auth={auth}>
			<NewsletterComponent {...props} />
		</LoginProvider>
	);
};
