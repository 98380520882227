import clsx from 'clsx';
import type { FC } from 'react';
import type { MenuItemEntity } from '@/types/entities';
import { capitalizeFirstLetter } from '@/utils/capitalize-first-letter';
import { IconMap, SocialIcon } from '@/components/raven/global/SocialIcon';

import styles from './styles.module.css';

interface SocialNetworksProps {
	ariaLabel?: string;
	authorName?: string;
	networks?: MenuItemEntity[];
}

export const SocialNetworks: FC<SocialNetworksProps> = ({
	ariaLabel,
	authorName,
	networks,
}) => {
	if (!networks || networks.length === 0) {
		return null;
	}

	const needsOnlyApostrophe = authorName?.endsWith('s');

	return (
		<ul className={clsx(styles.networksList)}>
			{networks.map(({ slug, url }) => {
				const networkIcon = IconMap[slug];

				if (!networkIcon) {
					return null;
				}

				const isAuthorsWebsite = slug === 'website';
				const label = isAuthorsWebsite
					? `Visit ${authorName}${needsOnlyApostrophe ? "'" : "'s"} website`
					: ariaLabel?.replace('%s', capitalizeFirstLetter(slug));

				return (
					<li key={slug}>
						<SocialIcon ariaLabel={label} network={slug} url={url} />
					</li>
				);
			})}
		</ul>
	);
};
